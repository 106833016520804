export default {
  namespaced: true,
  unit: {
    title: "instalação | instalações",
    all: "todas os instalações",
    description: "Descrição",
    address_1: "Endereço",
    address_2: "referência",
    exterior_number: "Numero Exterior",
    suburb: "Colônia",
    number: "Número",
    postal_code: "C.P",
    route: "Rota",
    plate_number: "Placa",
    vin: "VIN",
    driver: "Conductor",
    provider: "Fornecedor",
    owner: "Concessionária",
    company: "Concessionária",
    vehicle: "É um veículo?",
    reaction_patrol: "patrulha de reação",
    allow_virtual_button_alarm: "Permitir botão Virtual",
    active_digital_output: "Permitir saída Digital",
    camera_count: "número de Câmeras",
  },
};
