export default {
  namespaced: true,
  unit: {
    title: "instalación | instalaciones",
    all: "todas las instalaciones",
    description: "descripción",
    address_1: "dirección",
    address_2: "referencia",
    exterior_number: "Numero Exterior",
    suburb: "Colonia",
    number: "Número",
    postal_code: "C.P",
    route: "Ruta",
    plate_number: "Placa",
    vin: "VIN",
    driver: "Conductor",
    provider: "Proveedor",
    owner: "Concesionario",
    company: "Compañia",
    vehicle: "¿Es vehículo?",
    reaction_patrol: "patrol de reacción",
    allow_virtual_button_alarm: "Permitir Botón Virtual",
    active_digital_output: "Permitir Salida Digital",
    camera_count: "Camaras",
  },
};
